export const autoReloadMesssages = {
    newVersionText: 'We just made an improvement to CTBIDS',
    clickToRefreshText: 'Please click to accept'
}

export const authErrorMessage = {
    facebookEmailEnable: 'Please enable share email option in facebook account setting to enable sign in with CTBIDS using facebook account',
    appleEmailEnable: 'Please enable share email option in apple account setting to enable sign in with CTBIDS using apple account'
}

